import React, { useEffect, useState } from 'react';
import axios from "axios";
import { domain } from "../../../services/constants";
import './PanelData.css';

const ProlificData = () => {

    const [prolificData, setprolificData] = useState([]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [ispublished, setispublished] = useState(false);

    // Function to toggle dropdown for a specific item
    const toggleDropdown = (index) => {
        setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h12",
        }).format(date);
    };

    const publishBtn = async (item) => {
        const token = localStorage.getItem("access"); // Replace with your token retrieval logic
        // const url = `https://api.prolific.com/api/v1/studies/${id}/transition`;
        const data = {
            id: item.project_id,
        };
        try {
            const response = await fetch(`${domain}/duonut/prolific_publish`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            const responseData = await response.json();
            if (response.status === 200) {
                console.log("Data Publish", responseData);
                axios({
                    method: "PATCH",
                    url: `${domain}/duonut/edit_prolific_data`,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        // Add your payload data here
                        id: item.id,
                        project_status: "PUBLISHED",
                    },
                })
                    .then(response1 => {
                        console.log('API Data:', response1);
                        setispublished(true);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }
        }
        catch (error) {
            console.error("Error in second API:", error);
            return false;
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("access");
        axios({
            method: "GET",
            url: `${domain}/duonut/get_prolific_data`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },

        }).then(data => {
            console.log('Full API Data:', data);
            setprolificData(data?.data);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });


    }, [ispublished]);

    return (
        <div>
            <div style={{ padding: "20px" }}>
                <h2 style={{ margin: "20px 0px", fontSize: "24px", fontWeight: "bold" }}>Prolific Data</h2>
                {prolificData && prolificData.length > 0 ? (
                    <div>
                        {prolificData.map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    textAlign: "left",
                                    background: "#ECEFF1",
                                    borderRadius: "5px",
                                    padding: "20px",
                                    margin: "10px 0px",
                                    position: "relative", // Ensure dropdown is positioned relative to this div
                                }}
                            >
                                {/* Header Section */}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <p style={{ fontWeight: "bold", fontSize: "18px" }}>Project {index + 1}</p>
                                    {/* Button for dropdown only if status is not PUBLISHED */}
                                    {item.project_status !== "PUBLISHED" && (
                                        <button
                                            className="dot-and-copy-btn"
                                            onClick={() => toggleDropdown(index)}
                                            style={{
                                                background: "transparent",
                                                padding: "10px",
                                                borderRadius: "5px",
                                                border: "none",
                                            }}
                                        >
                                            <i
                                                className="fa fa-ellipsis-h"
                                                aria-hidden="true"
                                                style={{ fontSize: "18px", color: "#333" }}
                                            ></i>
                                        </button>
                                    )}
                                </div>

                                {/* Dropdown Menu */}
                                {openDropdownIndex === index && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            right: "0",
                                            // top: "100%",
                                            background: "#fff",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                            borderRadius: "5px",
                                            padding: "10px",
                                            zIndex: 10,
                                            minWidth: "150px",
                                        }}
                                    >
                                        <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                                            <li
                                                style={{
                                                    padding: "8px 12px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => publishBtn(item)}
                                            >
                                                Publish Panel
                                            </li>
                                        </ul>
                                    </div>
                                )}

                                {/* Display Project Information */}
                                <div>
                                    <p style={{ fontSize: "16px" }}>
                                        {item.title} <br />
                                        {item.survey_link} &nbsp;
                                        <button className="dot-and-copy-btn" style={{ border: "none", background: "transparent" }}>
                                            <i className="fa fa-clone" aria-hidden="true" style={{ fontSize: "14px" }}></i>
                                        </button>
                                    </p>

                                    <span style={{ color: "grey", fontSize: "14px" }}>
                                        Created {formatDate(item.created_at)}
                                    </span>

                                    {/* Project Status */}
                                    <div>
                                        {item.project_status === "UNPUBLISHED" ? (
                                            <span style={{ color: "black", fontWeight: "bold" }}>Draft</span>
                                        ) : item.project_status === "PUBLISH" ? (
                                            <span style={{ color: "green", fontWeight: "bold" }}>Active</span>
                                        ) : (
                                            <span>{item.project_status}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>No data available</div>
                )}
            </div>
        </div>

    );
};

export default ProlificData;

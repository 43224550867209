import React, { useState, useEffect } from 'react';
import './Element.css';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { saveQuizAnsAsync, saveQuizResponse, showDuonutresponse } from '../../redux/slices/DuonutReducer';
import imgPreview from '../../assets/Images/image-preview.png';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { EditText, EditTextarea } from "react-edit-text";
import { getOpenEndedQuestionAsync } from "../../redux/slices/AiDuonutNewReducer";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus, setTextFormData } from '../../redux/slices/userActivity';
import { showduonutData } from '../../redux/slices/DuonutReducer';

var aiCount = 0;
var aiConversation = [];
const RadioImage = ({
    slideId,
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, maxLength, gap,
        labelFontSize, labelFontWeight, labelColor, optionsImage, imageWidth, imagePadding, fieldPosition, required,
        openEnded, openEndedObjective, openEndedFeedbackDesired,
    },
    elementHoverAttr,
    conditions,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { userActivities, selectedUserActivity, elements, selectedComponent, selectedSlide, textFormData } = useSelector((state) => state.userActivity);
    const { viewer_display_id, viewer_nick_name } = useSelector((state) => state.duonut);
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const { width } = useWindowDimensions();
    const [hover, setHover] = useState(-1);
    const [isOther, setisOther] = useState(false);
    const [inputText, setInputText] = useState(text);
    const [loading, setloading] = useState(false);
    const [textField, setTextField] = useState('');

    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];

    const [shuffledOptions, setShuffledOptions] = useState([]);

    useEffect(() => {
        // Combine options with their images
        const combined = options.map((item, index) => ({
            item,
            image: optionsImage[index],
        }));

        if (location.pathname.includes("/duonut/")) {
            // Shuffle the combined array (Fisher-Yates algorithm)
            for (let i = combined.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [combined[i], combined[j]] = [combined[j], combined[i]];
            }
        }

        setShuffledOptions(combined);
    }, [options, optionsImage]);

    useEffect(() => {
        setisOther(false);
        aiCount = 0;
        aiConversation = [];
    }, []);


    const handleQuestionAnswerInputChange = (e) => {
        console.log(e)
        if (location.pathname.includes("/duonut/")) {
            setTextField(e.target.value);
            if (e.keyCode === 13) {
                document.getElementById("AiSubmitBtn").click();
            }
        }
    }
    const submitResponse = (item) => {
        if (location.pathname.includes("/duonut/") && !openEnded) {
            // console.log("ans", item)

            setSelectedAnswer(item); // Update selectedAnswer state

            // if (location.pathname.includes("/duonut/")) {
            const save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0]?.watch_id || "",
                result: 0,
                question: text || "",
                answered_text: item,
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "RadioImage",
            };

            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));
            dispatch(saveQuizAnsAsync(formData));

            const save_response = {
                id,
                type: "RadioImage",
                step: selectedSlide + 1,
                question: text || "",
                answered_text: item,
            };
            dispatch(saveQuizResponse(save_response));
            var nextPage = selectedSlide + 1;

            setTimeout(() => {
                dispatch(updateGoToSlide(nextPage));
            }, 100);
        } else if (location.pathname.includes("/duonut/") && openEnded) {
            console.log("question", inputText, textField);
            setSelectedAnswer(item); // Update selectedAnswer state

            var userIsPro = false;
            if (duonutData?.created_by.pro_user) {
                userIsPro = true;
            } else if (duonutData?.created_by.expire_date) {
                const currentDate = new Date();
                const dateToCompare = new Date(duonutData?.created_by.expire_date);
                const comparisonResult = currentDate < dateToCompare;
                if (comparisonResult) {
                    userIsPro = true;
                } else {
                    userIsPro = false;
                }
            }
            if ((!userIsPro && aiCount < 2) || (userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit))) {

                setloading(true);

                aiConversation = [...aiConversation,
                { question: inputText, answer: textField },
                ];

                var get_ans = {
                    question: inputText,
                    answer: item,
                    objective: openEndedObjective,
                    feedbackDesired: openEndedFeedbackDesired,
                    aiConversation: aiConversation,
                }

                dispatch(getOpenEndedQuestionAsync(get_ans)).then((res) => {
                    console.log(res);
                    if (res?.question) {
                        console.log(res?.question);
                        setInputText(res?.question);
                        if (document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`)) {
                            document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`).value = "";
                        }
                        setloading(false);

                        var save_ans = {
                            display_id: viewer_display_id,
                            watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                            result: 1,
                            question: inputText ? inputText : "",
                            answered_text: item,
                            // end: lastQue ? "1" : "0",
                            nickname: viewer_nick_name,
                            step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                            type: "QuestionAnswer",
                            // country: location[0] ? location[0].address?.country : "",
                            // city: location[0] ? location[0].address?.state_district : "",
                            // location: location[0] ? location[0].display_name : "",
                            // url_params: props.details.queryParamsObject
                        }
                        console.log(save_ans);
                        const formData = new FormData();
                        Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

                        dispatch(saveQuizAnsAsync(formData));

                        var save_response = {
                            type: "QuestionAnswer",
                            step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                            question: inputText ? inputText : "",
                            answered_text: item,
                        }
                        dispatch(saveQuizResponse(save_response));

                        aiCount++;

                    }
                }).catch((e) => {
                    console.log(e.message, e);
                });

            } else {
                var save_ans = {
                    display_id: viewer_display_id,
                    watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                    result: 1,
                    question: inputText ? inputText : "",
                    answered_text: item,
                    // end: lastQue ? "1" : "0",
                    nickname: viewer_nick_name,
                    step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                    type: "QuestionAnswer",
                    // country: location[0] ? location[0].address?.country : "",
                    // city: location[0] ? location[0].address?.state_district : "",
                    // location: location[0] ? location[0].display_name : "",
                    // url_params: props.details.queryParamsObject
                }
                console.log(save_ans);
                const formData = new FormData();
                Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

                dispatch(saveQuizAnsAsync(formData));

                var save_response = {
                    type: "QuestionAnswer",
                    step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                    question: inputText ? inputText : "",
                    answered_text: item,
                }
                dispatch(saveQuizResponse(save_response));

                var nextPage = selectedSlide + 1;

                setTimeout(() => {
                    dispatch(updateGoToSlide(nextPage));
                }, 100);
            }

        }
    }
    const handleInputFocus = () => dispatch(updateElementInputFocus(true));
    const handleInputBlur = () => dispatch(updateElementInputFocus(false));

    return (<>
        {aiCount === 0 && <div style={{ display: "flex", flexDirection: "column", gap }}>
            <div style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing,
            }}>
                {text}
            </div>

            <div style={{
                display: "flex",
                justifyContent: textAlign,
                alignItems: 'center',
                flexDirection: (viewType === 3 || width < 800) ? "column" : fieldPosition
            }}>
                {shuffledOptions.map((option, i) => (
                    <div key={i} style={{ display: "flex", alignItems: "center", lineHeight }}>
                        <input
                            type="radio"
                            id={`radio_option${i}`}
                            name="radio_option"
                            style={{
                                width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px", accentColor: color
                            }}
                            checked={selectedAnswer.includes(option.item)}
                            disabled={maxLength && maxLength === selectedAnswer.length && !selectedAnswer.includes(option.item)}
                            onChange={() => {
                                if (!(maxLength && maxLength === selectedAnswer.length && !selectedAnswer.includes(option.item))) {
                                    submitResponse(option.item);
                                }
                            }}
                        />
                        <div
                            style={{
                                display: "flex", flexDirection: "column",
                                backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                                border: hover === i ? elementHoverAttr?.border : border || "0",
                                color: hover === i ? elementHoverAttr?.color : color,
                                borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                                textTransform, textAlign, letterSpacing, padding, margin, cursor: location.pathname.includes("/duonut/") ? "pointer" : "default",
                                width: "100%", height: "100%",
                            }}
                            onMouseEnter={() => setHover(i)}
                            onMouseLeave={() => setHover(-1)}
                            onClick={() => {
                                if (!(maxLength && maxLength === selectedAnswer.length && !selectedAnswer.includes(option.item))) {
                                    submitResponse(option.item);
                                }
                            }}
                        >
                            <img
                                src={option.image || imgPreview}
                                style={{ width: imageWidth, height: imageWidth, objectFit: "cover", padding: imagePadding, boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)" }}
                                alt=""
                            />
                            <div>{option.item}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>}
        {openEnded && aiCount > 0 && <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: "column", height: "300px", width: "600px" }}>
            <div
                style={{
                    backgroundColor: "transparent",
                    border: "0",
                    outline: "none",
                    // display: "flex",
                    // alignItems: "center",
                    width: "100%",
                    color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                    textAlign, lineHeight, letterSpacing, padding
                }}
            >{inputText}</div>
            <div style={{ display: "flex" }}>
                <input type='text' key={`qainput-${selectedSlide + 1}-ai${aiCount}`}
                    id={`qainput-${selectedSlide + 1}-ai${aiCount}`}
                    style={{
                        width: "100%", minHeight: "40px",
                        color, fontFamily, fontSize: fontSize - 4, fontStyle, textDecoration, textTransform,
                        textAlign, lineHeight, letterSpacing, padding: "4px 10px", boxSizing: "border-box",
                        backgroundColor, border: '1px solid gray', margin: "0px 5px", borderRadius
                    }}
                    onKeyUp={(e) => handleQuestionAnswerInputChange(e)}
                    readOnly={location.pathname.includes("/duonut/") ? false : true}
                    required={required}
                    placeholder={"Type your answer here.."}
                />
                <button id="AiSubmitBtn" onClick={() => submitResponse(textField)}
                    style={{ backgroundColor: "#4299e1", color: "white", borderRadius: "6px", padding: '1rem', cursor: "pointer" }}>
                    {loading ? <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "1rem" }} />
                        : <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>}
                </button>
            </div>
        </div>}
    </>
    );
}

export default RadioImage;
